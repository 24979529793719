import Icon from "@/components/Icon";
import { Input } from "ant-design-vue";
// import "../ajax";

const components = [Icon];

const install = app => {
  const componentsInstall = app => {
    if (componentsInstall.installed) return;
    components.map(component => app.component(component.name, component));
  };

  const installObj = {
    install: componentsInstall,
    ...components
  };

  app.use(installObj).use(Input);
};

export default install;
