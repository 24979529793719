<template>
  <div :style="{ height: height + 'px', lineHeight: height + 'px' }">
    <svg :style="{ height: height, width: width }" aria-hidden="true">
      <use :xlink:href="`#${name}`"></use>
    </svg>
  </div>
</template>
<script>
export default {
  name: "Icon",
  props: {
    svgClass: String,
    name: String,
    height: String,
    width: String
  }
};
</script>
<style lang="less" scoped></style>
