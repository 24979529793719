import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login/Main.vue"),
    meta: { title: "登录页" }
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/ErrorPage/404.vue"),
    hidden: true,
    meta: { title: "404" }
  },
  {
    path: "/error",
    name: "error",
    component: () => import("@/views/ErrorPage/error.vue"),
    hidden: true,
    meta: { title: "error" }
  },
  {
    path: "/:catchAll(.*)",
    name: "*404",
    redirect: "/404",
    hidden: true
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
