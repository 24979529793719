// import { isDevMode } from "../../env";

export const addHeaders = func => (next, config) => {
  if (config.headers) {
    config.headers = { ...config.headers, ...func() };
  } else {
    config.headers = func();
  }
  return next();
};

export const defaultRequestHeaders = addHeaders(() => {
  // FIXME: 临时写
  const headers = {};
  if (Date.now.toString() !== "1") {
    headers["user-type"] = 1;
    headers["platform-id"] = 1;
    headers["tenant-id"] = 1;
    headers["company-id"] = 0;
    headers["db-no"] = 1;
    headers["X-Requested-With"] = "XMLHttpRequest";
    // headers['XK-tenant'] = '{"platformId":1,"companyId":1,"tenantId":1,"taxSourceId":1,"businessScope":1}';
    // headers['XK-tenant-user'] =
    //   '{"id":1,"type":1,"name":"test","username":"admin","mobile":"00000000000","platformId":0}';
  }
  // TODO: 添加全局的预置请求头

  return headers;
});
