export const baseUrl = (next, config) => {
  const path =
    process.env.NODE_ENV === "production"
      ? process.env.VUE_APP_PATH
      : process.env.VUE_APP_BASEURL;
  if (!config.baseURL) {
    config.baseURL = path;
  }

  return next();
};
