import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

/**
 * @name 统一注册插件、样式
 */
import "ant-design-vue/dist/antd.css";
import "./styles/antd-theme.less";
import "@/styles/index.less"; // glob less
import "@wlhy-web-styles/theme-pc-s";
import install from "./utils/install";

const app = createApp(App)
  .use(store)
  .use(router);

install(app);

app.mount("#app");
