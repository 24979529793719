import { buildApi2 } from "@/utils/ajax";

const basicApi = buildApi2("");

// 账号密码登录
export const loginApi = basicApi({
  url: "/oauth/login",
  withCredentials: true,
  meta: { form: true }
});

// 获取用户信息
export const userApi = basicApi({
  url: "/oauth/user_info",
  withCredentials: true
});

// 手机验证码登录
export const mobileLoginApi = basicApi({
  url: "/oauth/login/mobile",
  withCredentials: true,
  meta: { form: true }
});

// 获取验证码
export const smsCodeApi = basicApi({
  url: "/oauth/sms/send",
  withCredentials: true
});
