import { loginApi, userApi } from "@/apis/login";
import { message } from "ant-design-vue";
// import router, { resetRouter } from "@/router";

const state = {
  token: localStorage.getItem("token") ? localStorage.getItem("token") : "", // 认证凭证'
  userName: "",
  roles: [],
  introduce: ""
};
const mutations = {
  DEL_TOKEN(state) {
    state.token = "";
    state.userName = "";
    state.roles = "";
    state.introduce = "";
    localStorage.removeItem("token");
  },
  SET_ROLES(state, payload) {
    state.roles = payload;
  },
  SET_NAME(state, payload) {
    state.userName = payload;
  },
  SET_INTRODUCE(state, payload) {
    state.introduce = payload;
  }
};
const actions = {
  // user login
  async _login(_, formData) {
    return new Promise((resolve, reject) => {
      loginApi(formData)
        .then(res => {
          if (res) {
            message.success("登录成功！");
            window.location.href = res;
          }
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  async _userInfo() {
    return new Promise((resolve, reject) => {
      userApi()
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  },
  // loginOut({ commit }) {
  //   commit("DEL_TOKEN");
  //   resetRouter();
  //   router.push({
  //     path: "/login",
  //     query: {
  //       redirect: "/"
  //     }
  //   });
  // },
  _getInfo({ commit }) {
    return new Promise(resolve => {
      //   getInfo()
      //     .then(res => {
      //       if (res.code === 0) {
      //         const { name, roles, introduce } = res.data;
      const roles = ["*404"];
      commit("SET_ROLES", roles);
      localStorage.setItem("roles", JSON.stringify(roles));
      //         commit("SET_NAME", name);
      //         commit("SET_INTRODUCE", introduce);
      //       } else {
      //         Message.error(res.msg);
      //       }
      //       resolve(res.data);
      //     })
      //     .catch(error => {
      //       reject(error);
      //     });
      resolve({
        roles: roles
      });
    });
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};
